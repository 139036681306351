<template>
  <div
    ref="sectionRef"
    class="relative py-10 lg:py-9"
    :class="data.backgroundColour ? 'bg-white' : 'bg-light-gray-300'"
  >
    <div class="h-[17px] w-full bg-[url(/assets/images/backgrounds/slashes.png)] bg-repeat" />
    <div class="container">
      <div
        v-if="data.headline"
        class="col-span-6 mb-6 mt-5 text-2xl text-blue-900 lg:col-span-3 lg:mt-20"
      >
        {{ data.headline }}
      </div>
      <div v-if="data.testimonials" class="min-h-[500px]">
        <template v-if="isSectionVisible">
          <LazyTestimonials :data="data.testimonials" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SectionTestimonialsListRecord } from '~/types/generated'

defineProps({
  data: {
    type: Object as PropType<SectionTestimonialsListRecord>,
    default: () => {}
  }
})

const sectionRef = ref<HTMLElement | null>(null)
const isSectionVisible = ref(false)

const { stop } = useIntersectionObserver(
  sectionRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isSectionVisible.value = true
    }
  },
  {
    threshold: 0
  }
)

onUnmounted(() => {
  stop()
})
</script>
